import { default as indexQsa9w1bThFMeta } from "C:/liketeste/siteLikeV2/pages/espresso-lw/index.vue?macro=true";
import { default as indexqKaftwmkswMeta } from "C:/liketeste/siteLikeV2/pages/index.vue?macro=true";
import { default as indexfLYwmOQXwgMeta } from "C:/liketeste/siteLikeV2/pages/planos-e-precos/index.vue?macro=true";
import { default as indexeByojyCMukMeta } from "C:/liketeste/siteLikeV2/pages/politica-privacidade/index.vue?macro=true";
import { default as indexiJjNnAqA8PMeta } from "C:/liketeste/siteLikeV2/pages/presenca-omnicanal/index.vue?macro=true";
import { default as index7nL9pfN38MMeta } from "C:/liketeste/siteLikeV2/pages/sobre-nos/index.vue?macro=true";
export default [
  {
    name: indexQsa9w1bThFMeta?.name ?? "espresso-lw",
    path: indexQsa9w1bThFMeta?.path ?? "/espresso-lw",
    meta: indexQsa9w1bThFMeta || {},
    alias: indexQsa9w1bThFMeta?.alias || [],
    redirect: indexQsa9w1bThFMeta?.redirect,
    component: () => import("C:/liketeste/siteLikeV2/pages/espresso-lw/index.vue").then(m => m.default || m)
  },
  {
    name: indexqKaftwmkswMeta?.name ?? "index",
    path: indexqKaftwmkswMeta?.path ?? "/",
    meta: indexqKaftwmkswMeta || {},
    alias: indexqKaftwmkswMeta?.alias || [],
    redirect: indexqKaftwmkswMeta?.redirect,
    component: () => import("C:/liketeste/siteLikeV2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfLYwmOQXwgMeta?.name ?? "planos-e-precos",
    path: indexfLYwmOQXwgMeta?.path ?? "/planos-e-precos",
    meta: indexfLYwmOQXwgMeta || {},
    alias: indexfLYwmOQXwgMeta?.alias || [],
    redirect: indexfLYwmOQXwgMeta?.redirect,
    component: () => import("C:/liketeste/siteLikeV2/pages/planos-e-precos/index.vue").then(m => m.default || m)
  },
  {
    name: indexeByojyCMukMeta?.name ?? "politica-privacidade",
    path: indexeByojyCMukMeta?.path ?? "/politica-privacidade",
    meta: indexeByojyCMukMeta || {},
    alias: indexeByojyCMukMeta?.alias || [],
    redirect: indexeByojyCMukMeta?.redirect,
    component: () => import("C:/liketeste/siteLikeV2/pages/politica-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: indexiJjNnAqA8PMeta?.name ?? "presenca-omnicanal",
    path: indexiJjNnAqA8PMeta?.path ?? "/presenca-omnicanal",
    meta: indexiJjNnAqA8PMeta || {},
    alias: indexiJjNnAqA8PMeta?.alias || [],
    redirect: indexiJjNnAqA8PMeta?.redirect,
    component: () => import("C:/liketeste/siteLikeV2/pages/presenca-omnicanal/index.vue").then(m => m.default || m)
  },
  {
    name: index7nL9pfN38MMeta?.name ?? "sobre-nos",
    path: index7nL9pfN38MMeta?.path ?? "/sobre-nos",
    meta: index7nL9pfN38MMeta || {},
    alias: index7nL9pfN38MMeta?.alias || [],
    redirect: index7nL9pfN38MMeta?.redirect,
    component: () => import("C:/liketeste/siteLikeV2/pages/sobre-nos/index.vue").then(m => m.default || m)
  }
]